<template>
<div class="hello">
        
  <v-container>
    <v-row justify="start">
      <v-col cols="12" sm="4" md="4" lg="4">
        <v-img
          height="1000"
          :gradient="gradient"
          :src="require('@/assets/finance.jpg')"
          >
        <h2 class="pt-4 ma-6 white--text">{{user_name}}<br>토니 퀀트에 오신것을 환영합니다.</h2>
        <h3
          class="pt-5 ma-10 text-left grey--text text--lighten-3"
        > 개인은 가치투자, 차트매매, 알고리즘 투자를 통해서는 기관을 이길수가 없다고 합니다.<br>
            개인이 시장평균 초과 수익을 얻기 위해서는 개별종목 투자 보다는 거시경제 리서치를 통해 투자전략을 수립하고 <br>
            해당 가설이 맞는지 백테스팅을 통해 검증하고 소액 실전투자를 통해 본인이 수립한 투자전략이 현실에서도 <br>
            잘 맞는지 확인하고 전략을 지속적으로 재검토, 조정, 재투자를 해야한다고 합니다. <br><br> 
            토니퀀트는 이러한 투자전략 싸이클에 맞추어 여러분들이 전략을 수립하고 검증할 수 있는 툴을 제공하고자 합니다.
          </h3>
        
        </v-img>
      </v-col>
      <v-col cols="12" sm="8" md="8" lg="8">
        <v-card      
            flat
        >
            <v-timeline
              align-top
              dense
            >
              <v-timeline-item
                  color="red lighten-2"
                  icon="mdi-star"
                  fill-dot
                  class="text-left"
              >
                  <v-card
                    color="red lighten-2"
                    dark
                  >
                  <v-card-title class="text-h6">
                    투자전략(아이디어) 수립
                  </v-card-title>
                  <v-card-text class="white text--primary">
                    <br>
                    <p>글로벌 경제지표의 추이 분석을 통해 물가 및 경쟁상황별 수익율이 높은 자산군에 투자하세요.</p>
                    <p>국내외 전문가가 수립한 전략을 참고해서 본인만의 투자 전략을 수립할 수 있습니다.</p>
                    <p>글로벌 자산배분은 상관성이 낮은 자산군별(주식, 채권, 부동산, 원자재 등)로 투자 자금을 배분하는 작업으로 투자 손실을
                        최소화하는 것입니다. 분산투자는 ETF를 통해 할수 있습니다. ETF는 주가지수 연동뿐만 아니라 채권, 금, 원자재 등
                        특정 자산군에 투자하는 ETF도 있습니다.</p>  
                    <br>
                    <div class="d-flex justify-space-around mb-6">     
                    <v-row>
                      <v-col class="d-flex justify-center" cols="12" sm="12" md="4" lg="4" xl="4">              
                        <v-btn
                            small
                            rounded 
                            color="red lighten-2"
                            outlined
                            @click="checkLogin('/economy')"
                        >
                            주요경제지표
                        </v-btn>
                      </v-col>
                      <v-col class="d-flex justify-center" cols="12" sm="12" md="4" lg="4" xl="4">
                        <v-btn
                            small
                            rounded                        
                            color="red lighten-2"
                            outlined
                            @click="checkLogin('/rule')"
                        >
                            국내주식
                        </v-btn>
                      </v-col>
                      <v-col class="d-flex justify-center" cols="12" sm="12" md="4" lg="4" xl="4">
                        <v-btn
                            small
                            rounded                         
                            color="red lighten-2"
                            outlined
                            @click="checkLogin('/assets')"
                        >
                            글로벌자산
                        </v-btn>
                      </v-col>
                    </v-row>
                    </div>
                  </v-card-text>
                  </v-card>
              </v-timeline-item>

              <v-timeline-item
                  color='purple darken-1'
                  icon='mdi-book-variant'
                  fill-dot                  
                  class="text-left"
              >
                  <v-card
                    color='purple darken-1'
                    dark
                  >
                  <v-card-title class="text-h6">
                    백테스트
                  </v-card-title>
                  <v-card-text class="white text--primary">
                    <br>
                    <p>국내 주식은 총 94개의 투자지표 조합을 통해 최고의 수익율을 낼수 있는 아이디어를 수립하여 백테스트를 통해 검증할 수 있습니다.</p>
                    <p>글로벌 자산배분을 통해 나만의 포트폴리오를 구성할 수 있으며, 또한 전문가의 포트폴리오를 밴치마크할 수 있습니다.
                        구성한 포트폴리오 백테스트를 통해 과거 수익율 분석할 수 있고 당일, 월누적, 연누적 수익율을 확인하실 수 있습니다." </p>
                    <br>
                    <div class="d-flex justify-space-around mb-6">                   
                    <v-btn
                        color='purple darken-1'
                        small
                        rounded                         
                        outlined
                        @click="checkLogin('/filter')"
                    >
                        국내주식
                    </v-btn>
                    <v-btn
                        color='purple darken-1'
                        small
                        rounded                         
                        outlined
                        @click="checkLogin('/etf_backtest')"
                    >
                        글로벌자산
                    </v-btn>
                    </div>
                  </v-card-text>
                  </v-card>
              </v-timeline-item>

              <v-timeline-item
                  color='green lighten-1'
                  icon='mdi-airballoon'
                  fill-dot                  
                  class="text-left"
              >
                  <v-card
                    color='green lighten-1'
                    dark
                  >
                  <v-card-title class="text-h6">
                    실전매매
                  </v-card-title>
                  <v-card-text class="white text--primary">
                    <br> 
                    <p>본인이 수립한 투자 전략을 백테스팅을 통해 검증하였다면 실전 투자를 하고, 
                        해당 전략이 올바르게 동작하는지 자신만의 포트폴리오를 통해 리스크 관리를 수행할 수 있습니다. </p>               
                    <br>
                    <div class="d-flex justify-space-around mb-6">     
                    <v-row>
                      <v-col class="d-flex justify-center" cols="12" sm="12" md="6" lg="6" xl="6">              
                        <v-btn
                            color='green lighten-1'
                            small
                            rounded                         
                            outlined
                            @click="checkLogin('/port')"
                        >
                            국내 포트폴리오
                        </v-btn>
                      </v-col>
                      <v-col class="d-flex justify-center" cols="12" sm="12" md="6" lg="6" xl="6">                      
                        <v-btn
                            color='green lighten-1'
                            small
                            rounded                         
                            outlined
                            @click="checkLogin('/etf_port')"
                        >
                            글로벌 포트폴리오
                        </v-btn>
                      </v-col>
                    </v-row>
                    </div>
                  </v-card-text>
                  </v-card>
              </v-timeline-item>
            </v-timeline>
          </v-card>
      </v-col>
    </v-row>
    </v-container>

  <!-- 알림 메세지 -->
  <v-snackbar
  v-model="snackbar"
  :vertical="vertical"
  :color="snackColor"          
  >
    {{ snackText }}
    <template v-slot:action="{ attrs }">
        <v-btn
        text
        v-bind="attrs"
        @click="snackbar = false"
        >
        Close
        </v-btn>
    </template>
  </v-snackbar>

</div>
</template>

<script>
  import {mapState} from 'vuex';
  export default {
   name: "HelloTony",
   data () {
      return {
        gradient: 'rgba(0,0,0,.7), rgba(0,0,0,.7)',
        user_name: null,

        snackbar: false,
        vertical: true,
        snackColor: null,
        snackText: null,
        
      }
   },
   computed: {
      ...mapState(['debug', 'user', 'avatar', 'isLogin', 'isLogout'])
   },

   created() {
      
      if (this.user) this.user_name = this.user+' 님 ';
   },


   methods: {
    
    checkLogin(to) {
      if (to == '/' && this.isLogin) this.$router.push(to);
      else if (to == '/economy' && this.isLogin) this.$router.push(to);
      else if (to == '/lei' && this.isLogin) this.$router.push(to);
      else if (to == '/filter' && this.isLogin) this.$router.push(to);
      else if (to == '/rule' && this.isLogin) this.$router.push(to);
      else if (to == '/port' && this.isLogin) this.$router.push(to);
      else if (to == '/power' && this.isLogin) this.$router.push(to);
      else if (to == '/assets' && this.isLogin) this.$router.push(to);
      else if (to == '/etf_backtest' && this.isLogin) this.$router.push(to);
      else if (to == '/etf_port' && this.isLogin) this.$router.push(to);
      else {
        this.snackbar = true;
        this.snackColor = 'warning';
        this.snackText = '로그인 또는 회원 가입하시기 바랍니다.';          
      }
    }
  },

  }
</script>

<style>
.hello{
   padding: 1px; 
}
</style>
